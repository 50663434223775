import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const Navigation = (props) => {
  const navigate = useNavigate();
  const [isCheck, setCheck] = useState(false);
  const navigation = (route) => {
    navigate(route)
  }
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onClickMenu = () => {
    setTimeout(() => {
      setCheck(!isCheck)
    }, 100)
  }

  if(window.location.pathname==="/payment"){
    return null
  }

  return (
    <>
      <section id="topbar" className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:hello@1qr4.in">hello@1qr4.in</a></i>
            <i className="bi bi-phone d-flex align-items-center ms-4"><a href="tel:+91 98333 03311">+91 98333 03311</a></i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
            <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
            <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
            <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
          </div>
        </div>
      </section >

      <header id="header" className="header d-flex align-items-center">

        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <h1>1qr4<span>.</span>in</h1>
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a href="#hero">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="/products">Product</a></li>
              <li><a href="#contact">Contact</a></li>
              <li><a href="#" onClick={()=>{window.open("https://web112.1qr4.me","self")}}>Manage account</a></li>
            </ul>
          </nav>

          <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

        </div>
      </header></>
  );
};
