import React from "react";

export const Footer = (props) => {
  
  const handleSocial = (url) => {
    window.open(url, "_blank")
  }

  if(window.location.pathname==="/payment"){
    return null
  }

  return (
    <footer id="footer" class="footer">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info">
            <a href="" class="logo d-flex align-items-center">
              <span>1qr4.in</span>
            </a>
            <p>Effortlessly manage your account from different providers on our unified platform. Scan your code or select your provider for a seamless experience.</p>
            <div class="social-links d-flex mt-4">
              <a onClick={() => handleSocial(props.data.twitter)} class="twitter"><i class="bi bi-twitter"></i></a>
              <a onClick={() => handleSocial(props.data.facebook)} class="facebook"><i class="bi bi-facebook"></i></a>
              <a onClick={() => handleSocial(props.data.instagram)} class="instagram"><i class="bi bi-instagram"></i></a>
              <a onClick={() => handleSocial(props.data.linkedin)} class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/terms">Terms</a></li>
              <li><a href="/privacy">Privacy</a></li>
              {/* <li><a href="/shipping">Shipping</a></li> */}
              <li><a href="/return">Refunds</a></li>
            </ul>
          </div>


          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p  >
              {props?.data?.address}
              <strong > Phone:</strong>{props?.data?.phone}<br />
              <strong>Email:</strong> {props?.data?.email}<br />
            </p>
          </div>
        </div>
      </div >
    </footer >
  );
};
