import React from "react";

export const Features = (props) => {
  return (
    <section id="hero" className="feature" style={{background:"none"}}>
      <div className="container position-relative">
        <div className="section-header" style={{paddingBottom:"30px"}} >
          <h2 >Features</h2>
        </div>
        <div className="row gy-4">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className={d.icon}></i></div>
                  <h4 className="title"><a href="#" className="stretched-link">{d.name}</a></h4>
                  <p>{d.text}</p>
                </div>
              </div>
            )) : ""}
        </div>
      </div>
    </section>
  );
};