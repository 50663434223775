import React, { useState, useEffect } from "react"
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
export const Landing = () => {

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <>
            <Header data={landingPageData.Header} feature={landingPageData.Features} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            {/* <Testimonials data={landingPageData.Testimonials} /> */}
            <Gallery data={landingPageData.Gallery} />
            <Contact data={landingPageData.Contact} />
        </>
    )
}