import React, { useState } from "react";
export const Contact = (props) => {

    const initialState = {
        name: "",
        email: "",
        message: "",
        subject: ""
    };
    const [{ name, email, message, subject }, setState] = useState(initialState);
    const [msg, setMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, message, subject })
        };
        {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
        fetch('https://112.1qr4.me/api/inquiry/create', options)
            .then((response) => {
                if (!response.ok) {
                    // If response is not ok, throw an error with the error message
                    return response.json().then(errorResponse => {
                        throw new Error(errorResponse.message || 'Server error');
                    });
                }
                setMessage(true)
                return response.json();
            }).catch(err => {
                alert(err.message)
            })
    };


    return (
        <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">

                <div class="section-header">
                    <h2>Contact</h2>
                    {/* <p>Nulla dolorum nulla nesciunt rerum facere sed ut inventore quam porro nihil id ratione ea sunt quis dolorem dolore earum</p> */}
                </div>

                <div class="row gx-lg-0 gy-4">
                    <div class="col-lg-4">
                        <div class="info-container d-flex flex-column align-items-center justify-content-center">
                            <div class="info-item d-flex">
                                <i class="bi bi-geo-alt flex-shrink-0"></i>
                                <div>
                                    <h4>Location:</h4>
                                    <p>{props?.data?.address}</p>
                                </div>
                            </div>

                            <div class="info-item d-flex">
                                <i class="bi bi-envelope flex-shrink-0"></i>
                                <div>
                                    <h4>Email:</h4>
                                    <p>{props?.data?.email}n</p>
                                </div>
                            </div>

                            <div class="info-item d-flex">
                                <i class="bi bi-phone flex-shrink-0"></i>
                                <div>
                                    <h4>Call:</h4>
                                    <p>{props?.data?.phone}</p>
                                </div>
                            </div>

                            <div class="info-item d-flex">
                                <i class="bi bi-clock flex-shrink-0"></i>
                                <div>
                                    <h4>Open Hours:</h4>
                                    <p>Mon-Sat: 11AM - 23PM</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-8">
                        <form class="php-email-form" onSubmit={handleSubmit}>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <input type="text" name="name" onChange={handleChange} class="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div class="col-md-6 form-group mt-3 mt-md-0">
                                    <input type="email" onChange={handleChange} class="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <input type="text" class="form-control" onChange={handleChange} name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control" name="message" onChange={handleChange} rows="7" placeholder="Message" required></textarea>
                            </div>
                            <div class="my-3">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                            </div>
                            <div class="text-center"><button type="submit">Send Message</button></div>
                            <div class="my-3">
                                {msg ? <div class="sent-message">Your message has been sent. Thank you!</div> : ""}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
