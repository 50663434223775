import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import crypto from 'crypto-js';
import Axios from 'axios';
// import checkout from './cashfree';


const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    console.log('razorpay loaded successfully');
    resolve(true);
  };
  script.onerror = () => {
    console.log('error in loading razorpay');
    resolve(false);
  };
  document.body.appendChild(script);
});

export const Payment = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let key = searchParams.get("sessionId")
  let BASE_URL = searchParams.get("callback")
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  const navigate = useNavigate();



  const displayRazorpay = async (options) => {
    try {
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js',
      );
      if (!res) {
        console.log('Razorpay SDK failed to load. Are you online?');
        return;
      }
      // All information is loaded in options which we will discuss later.
      const rzp1 = new window.Razorpay(options);
      // If you want to retreive the chosen payment method.
      rzp1.on('payment.submit', (response) => {
        console.log("dsfds1111")
        paymentMethod.current = response.method;
      });

      // To get payment id in case of failed transaction.
      rzp1.on('payment.failed', (response) => {
        console.log("dsfds")
        paymentId.current = response.error.metadata.payment_id;
      });

      // to open razorpay checkout modal.
      rzp1.open();
    } catch (err) {
      console.log("Err", err)
    }
  };


  // informing server about payment
  const handlePayment = async (status, orderDetails = {}) => {
    try {
      let urlId = BASE_URL.match(/\d+/)[0]
      await Axios.post(`https://console${urlId}.1qr4.me/api/order/status`,
        {
          status,
          orderDetails,
        });
      window.open(`${BASE_URL}/payment/success`, "_self")
    }
    catch (err) {
      window.open(`${BASE_URL}/payment/failed`, "_self")
    }
  };


  const options = {
    key: process.env.REACT_APP_RAZOR_KEY, // key id from props
    // amount, // Amount in lowest denomination from props
    currency: "INR", // Currency from props.
    // name: 'amit', // Title for your organization to display in checkout modal
    image: "https://web112.1qr4.me/assets/logo-7aPUJors.svg", // custom logo  url
    order_id: key, // order id from props
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      paymentId.current = response.razorpay_payment_id;
      console.log("res", response)
      // Most important step to capture and authorize the payment. This can be done of Backend server.
      const succeeded = crypto.HmacSHA256(`${key}|${response.razorpay_payment_id}`, process.env.REACT_APP_RAZOR_SECRET).toString() === response.razorpay_signature;

      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        handlePayment('succeeded', {
          key,
          paymentId,
          signature: response.razorpay_signature,
        });
      } else {
        window.open(`${BASE_URL}/payment/failed`, "_self")
      }
    },
    modal: {
      confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason) => {
        const {
          reason: paymentReason, field, step, code,
        } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
        if (reason === undefined) {
          console.log('cancelled');
          window.open(`${BASE_URL}/payment/failed`, "_self")
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === 'timeout') {
          console.log('timedout');
          window.open(`${BASE_URL}/payment/failed`, "_self")
        }
        // Reason 3 - When payment gets failed.
        else {
          console.log('failed');
          window.open(`${BASE_URL}/payment/failed`, "_self")
        }
      },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default. 
    retry: {
      enabled: false,
    },
    timeout: 900, // Time limit in Seconds
    theme: {
      color: 'green', // Custom color for your checkout modal.
    },
  };

  useEffect(() => {
    if (key) {
      displayRazorpay(options);
    } else {
      navigate("/")
    }
    window.alert = customAlert;
    return () => {
      // Restore original window.alert function
      window.alert = originalAlert;
      // You may add cleanup logic if needed
    };
  }, [key])

  const originalAlert = window.alert; // Store reference to original window.alert function

  const customAlert = (message) => {
    console.log('Alert triggered with message:', message);
    originalAlert(message);
    window.open(`${BASE_URL}/payment/failed`, "_self")
  };

  return (
    <div className="text-left" style={{ position: "absolute", top: "44%", left: "33%" }}>
      <div className="container text-center">
        <div class="spinner-grow text-success " role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  );
};
