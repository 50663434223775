import React from "react";

export const Return = (props) => {
    return (
        <div className="container mt-5" >
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="about-text">
                        <h2>Returns and Exchanges:  </h2>


                        <p>We understand that sometimes things don't work out as expected. Here's our policy on returns and exchanges to ensure your satisfaction:</p>


                        <b>1. Return Eligibility:</b>
                        <p>- To be eligible for a return, the item must be unused and in its original condition.</p>
                        <p>- Returns are generally accepted within two [2] days from the date of purchase.</p>


                        <b>
                            2. Initiating a Return:
                        </b>
                        <p>  To initiate a return, please contact <a href="#" >support@1qr4.in </a> with your order number and details of the item you wish to return.
                        </p>

                        {/* <b>2. Return Shipping:</b>
                        <p>- Customers are responsible for the cost of return shipping unless the return is due to a product defect or an error on our part.</p>
                        <p>- Please use a trackable shipping method for your return.</p> */}


                        <b>2. Refunds:</b>
                        <p>- Refunds will be processed once we receive and inspect the returned item.</p>
                        <p>- It may take ten [10] business days for the refund to be reflected in your account.</p>


                        {/* <b>5. Exchanges:</b>
                        <p> - If you received a defective or damaged item, we'll gladly exchange it for a new one. Please contact us on <a href="#">support@1qr4.in</a> to arrange the exchange.</p>

                        <b>6. Non-Returnable Items:</b>
                        <p>- Certain items, such as personalized or digital products, may not be eligible for return. This will be indicated during the checkout process. <b>Further items once activated cannot be returned</b></p>

                        <b>7. Return Address:</b>
                        <p>- 905, Mayflower, Hiranandani Meadows, Gladys Alvares Road, Thane West, Mumbai 400610.</p> */}

                        <b>3. Contact Us:</b>
                        <p>- If you have any questions or concerns about our return policy, please reach out to our customer support at<a href="#"> support@1qr4.in </a></p>


                        <p>We appreciate your understanding and cooperation in making the returns process as smooth as possible. Your satisfaction is our priority!</p>

                    </div>
                </div>
            </div>
        </div>
    );
};
