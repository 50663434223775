import React from "react";

export const Header = (props) => {

  return (
    <section id="hero" className="hero">
      <div className="container position-relative">
        <div className="row gy-5 mt-5" data-aos="fade-in">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
            <h2>{props?.data?.title}</h2>
            <p>{props?.data?.paragraph}</p>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a style={{cursor:"pointer"}} onClick={() => window.open(props?.data?.register_link, "_blank")} className="btn-get-started">Get Started</a>
              <a  href="https://www.youtube.com/watch?v=pu7keyFiqgQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <img src="img/hero-img.svg" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
          </div>
        </div>
      </div>
    </section >
  );
};
