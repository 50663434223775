import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    // <div id="portfolio" className="text-center">
    //   <div className="container">
    //     <div className="section-title">
    //       <h2>Gallery</h2>
    //       <p>
    //         Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
    //         dapibus leonec.
    //       </p>
    //     </div>
    //     <div className="row">
    //       <div className="portfolio-items">
    //         {props.data
    //           ? props.data.map((d, i) => (
    //               <div
    //                 key={`${d.title}-${i}`}
    //                 className="col-sm-6 col-md-4 col-lg-4"
    //               >
    //                 <Image
    //                   title={d.title}
    //                   largeImage={d.largeImage}
    //                   smallImage={d.smallImage}
    //                 />
    //               </div>
    //             ))
    //           : "Loading..."}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <section id="portfolio" class="portfolio sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Gallery</h2>
        </div>

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">
          <div class="row gy-4 portfolio-container">
            {props.data
              ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  class="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <a href={`${d.largeImage}`} target="_blank" data-gallery="portfolio-gallery-app" class="glightbox"><img src={d.largeImage} class="img-fluid" alt="" /></a>
                  </div>
                </div>
              )) : ""}
          </div>
        </div>
      </div>
    </section>
  );
};
