import React from "react";

export const About = (props) => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>About Us</h2>
          {/* <p>Aperiam dolorum et et wuia molestias qui eveniet numquam nihil porro incidunt dolores placeat sunt id nobis omnis tiledo stran delop</p> */}
        </div>

        <div className="row gy-4">
          <div className="col-lg-6">
            <h3>Welcome to 1qr4.in managed by Oyekidhar Private Limited. </h3>
            <img src="img/about.png" className="img-fluid rounded-4 mb-4" alt="" />
            <p>We are a registered startup under the Startup India program and are passionate about enhancing personal identification, security, and brand experiences.</p>
            <p>We offer high-quality, customizable products and cutting-edge technology solutions tailored to meet your unique needs.</p>
          </div>
          <div className="col-lg-6">
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
                Our journey began with a simple realization: the importance of identifying and safeguarding personal belongings
              </p>
              <ul>
                <li><i className="bi bi-check-circle-fill"></i> We craft durable and stylish bag tags that go beyond identification, adding a touch of personalization to prevent loss or mix-ups.</li>
                <li><i className="bi bi-check-circle-fill"></i> We also provide customizable technology solutions to optimize processes, enhance productivity, and drive business growth. Your privacy and security are paramount, and we offer the option to operate services from your infrastructure for complete control.</li>
                <li><i className="bi bi-check-circle-fill"></i> Inclusivity is important to us. Our objective is to make emergency contact services accessible to everyone. We believe in ensuring the safety and well-being of individuals from all walks of life with our accessible solutions.</li>
              </ul>
              <p>
                Join us on this journey of innovation, customization, and empowerment.
                Together, let’s transform the way we identify, protect, and connect. Experience the Oyekidhar difference today.
              </p>

              <div className="position-relative mt-4">
                <img src="img/about-2.png" className="img-fluid rounded-4" alt="" />
                <a href="https://www.youtube.com/watch?v=mK2dDPrnxdg&t=70s" className="glightbox play-btn"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};
