import React from "react";

export const Privacy = (props) => {
    return (

        <div className="container mt-5">
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="about-text">
                        <h2>Privacy Policy:  </h2>

                        <p>Oyekidhar Private Limited ("us", "we", or "our") operates <a href="https://www.oyekidhar.com" target="_blank">https://www.oyekidhar.com </a>& <a href="https:///www.1qr4.in" target="_blank">https:///www.1qr4.in</a> & <a href="https:///www.1qr4.me" target="_blank" >https:///www.1qr4.me</a> and all the subdomains on each url, along with mobile applications on different app stores (the "Apps"  "Sites"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Sites & Apps</p>
                        <p>
                            We use your Personal Information only for providing you access to and improving the Site & Apps. By using the Site & Apps, you agree to the collection and use of information in accordance with this policy.
                        </p>
                        <b>Information Collection And Use</b>
                        <p>While using our Site & Apps, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you or your emergency contacts. Personally identifiable information may include, but is not limited to your or your emergency contacts name, contact number.</p>

                        <b>Log Data</b>
                        <p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser or mobile operating system version, the pages of our Sites , Apps that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
                        <p>- Certain products may not require shipping and will be available for immediate download or pickup.</p>
                        <p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this data</p>
                        <p>In addition, we record & store all the conference calls made when an emerging event is reported. These recordings are not made public and are only used to monitor any misuse and help authorized agencies or insurance policy issuer with <b>accepted protocols</b></p>

                        <b>Communications
                        </b>
                        <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that we would like to share with you on new products or features offered.</p>

                        <b>Cookies</b>
                        <p>Cookies are files with a small amount of data, which may include an anonymous unique identifier.</p>
                        <p>Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
                        <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>

                        <b>Security</b>
                        <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                        <b>Changes To This Privacy Policy</b>
                        <p> This Privacy Policy is effective as of (add date) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
                        <p> We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
                        <p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>

                        <b>Contact Us</b>
                        <p>If you have any questions about this Privacy Policy, please contact us on <a href="#"> support@1qr4.in</a></p>

                    </div>
                </div>
            </div>
        </div>
    );
};
