import React from "react";
import { ProductCard } from "./product_card";
export const Product = (props) => {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="about-text">
                        <h2>Products  </h2>

                        <p>Please note that in some cases due to the cost of the physical products being less than the cost of package & delivery, we have bundled the products into sets of 3 or 6.</p>
                        <p>
                            These sets can be activated on one account having a single set of emergency contacts or separate account each having different emergency contacts.
                        </p>
                        <p>The product does not include the service subscription, you can activate the subscription service once the tag is activated.</p>
                        <b>Plans</b>
                        <p>Service Activation or Service Recharge can be done online from your account after initially setting up your account </p>
                        <p>Plans start from ₹5 per month</p>
                        <ProductCard />
                        <b>Activate</b>
                        <p>To activate the product purchased, follow these steps</p>
                        <ol >
                            <li >
                                Scan the QR code on the product using your camera phone.
                            </li>
                            <li >
                                Enter the activation code you have received along with the product. The activation code would vary based on the product dispatched. Please keep the code till you have activated the product.
                            </li>
                            <li >
                                You need to attach the product to your account.
                                <ol style={{ listStyleType: "lower-alpha" }}>
                                    <li >
                                        If you have registered any product previously, it is recommended you use that registered number.
                                    </li>
                                    <li >
                                        If you have not previously registered, please use the mobile number you wish to register the product.
                                    </li>
                                </ol>
                            </li>
                            <li >
                                After entering your mobile number, you would receive an OTP to confirm.
                            </li>
                            <li >
                                Enter the OTP received.
                                <ol style={{ listStyleType: "lower-alpha" }}>
                                    <li >
                                        If you have registered previously. The product has been registered to your account.
                                    </li>
                                    <li >
                                        If you have not registered previously, you would need to complete a short registration form.
                                    </li>
                                </ol>
                            </li>
                            <li >
                                It is always recommended to keep your emergency contacts updated.
                            </li>
                            <li >
                                You can add or remove emergency contacts from anywhere by just logging into your Account.
                            </li>
                            <li >
                                To add an emergency contact, after entering the emergency contact number, that contact would receive an OTP to give consent to be your emergency contact.
                                <ol style={{ listStyleType: "lower-alpha" }}>
                                    <li >
                                        An emergency contact can also disconnect from being an emergency contact to you by logging into their account.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};
