import React from "react";

export const Services = (props) => {
  return (
    <section id="services" class="services sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Advantages</h2>
          {/* <p>Aperiam dolorum et et wuia molestias qui eveniet numquam nihil porro incidunt dolores placeat sunt id nobis omnis tiledo stran delop</p> */}
        </div>

        <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">
          {props.data ? props.data.map((d, i) => (
            <div key={`${d.name}-${i}`} class="col-lg-4 col-md-6">
              <div class="service-item  position-relative">
                <div class="icon">
                  <i class={d.icon}></i>
                </div>
                <h3>{d.name}</h3>
                <p>{d.text}</p>
                {/* <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a> */}
              </div>
            </div>
          )) : ""}
        </div>
      </div>
    </section>
  );
};
